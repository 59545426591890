<script>
import router from "../../router/routes";
import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import Footer from "@/components/footer";

export default {
  components: { NavBar, SideBar, Footer },
  beforeCreate: function () {
    document.body.className = "main";
  },
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    checkToEnterNextBoard() {},
    checkToEnterInfinity() {},
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar :isCondensed="isMenuCondensed" />
    <SideBar :isCondensed="isMenuCondensed" />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div class="d-none">
            <router-link
              class="bg-soft-success p-3 mb-3 rounded d-flex align-items-center"
              tag="a"
              :to="'/boards'"
            >
              <i class="lnr lnr-alarm text-dark" style="font-size: 30px"></i>
              <div class="px-3 flex-fill">
                <h6 class="m-0 font-size-15">
                  Você acabou de receber 4 doações em seu tabuleiro
                  <strong>"Bronze"</strong>, faça a sua doação no tabuleiro
                  <strong>"Prata"</strong> para continuar recebendo suas doações e
                  completar o seu tabuleiro.
                </h6>
              </div>
              <div
                class="bg-dark text-white font-weight-bold text-center rounded py-2 px-3"
              >
                <vue-countdown-timer
                  :start-time="'2023-11-09 16:00:00'"
                  :end-time="'2023-11-09 17:00:00'"
                >
                  <template slot="countdown" slot-scope="scope">
                    <span>{{scope.props.minutes}}</span>:<span>{{scope.props.seconds}}</span>
                  </template>

                  <template slot="end-text">
                    00:00
                  </template>
                </vue-countdown-timer>
              </div>
            </router-link>
          </div>

          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
